import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SeoMeta from "../components/seo"

import shared from 'core-js-pure/internals/shared'

const DiagPage = () => (
  <Layout>
    <SeoMeta title="Home" />
    <Helmet>
      <style>{`
        .css-grid {
          display: grid;
          grid-template-columns: 200px 1vw;
          grid-template-rows: repeat(6, 1fr);
        }
        .grid-cell.bold {
          font-weight: 800;
        }
      `}</style>
    </Helmet>
    <h1>Hello Friend</h1>
    <p>Here are the details</p>
    <div className="css-grid">
      <div className="grid-cell bold">Environment:</div>
      <div className="grid-cell">{ process.env.NODE_ENV }</div>
      <div className="grid-cell bold">Version of core-js:</div>
      <div className="grid-cell">{shared('versions')[0]['version']}</div>
    </div>
    {/* <p><strong>Environment:</strong> { process.env.NODE_ENV }</p>
    <p>
      <strong>Using core-js version:</strong> {shared('versions')[0]['version']}
    </p> */}
  </Layout>
)

export default DiagPage